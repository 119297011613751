<template>
  <div class="d-flex flex-column fill-height overflow-hidden">
    <div ref="tasks_body" class="pt-3 rounded-scroll-track overflow-auto fill-height" @scroll="loadMoreTasks">

      <div v-if="!getProjectSideDrawerTasks.data.length" class="pa-3 text-center font-weight-semi-bold">
        No tasks available
      </div>

      <div v-if="!loading && getProjectSideDrawerTasks.data.length" class="px-6">
        <div class="text-captions-1 gray-80--text mb-2">
          Task{{ getProjectSideDrawerTasks.data.length === 1 ? '' : 's' }} ({{ getProjectSideDrawerTasks.data.length }})
        </div>
        <v-divider/>
      </div>

      <v-data-table
        v-show="getProjectSideDrawerTasks.data.length"
        :custom-sort="customSort"
        :headers="headers"
        :items="getProjectSideDrawerTasks.data"
        :items-per-page="-1"
        class="task-table"
        hide-default-footer
        mobile-breakpoint="280"
        must-sort
        sort-by="name"
      >
        <template v-slot:item="{ item }">
          <tr class="relative">
            <td class="pr-0">
              <v-hover v-slot="{ hover }">
                <UiCheckbox
                  :readonly="item.status === $config.tasks.status.done"
                  :input-value="item.status === $config.tasks.status.done"
                  color="accent-100"
                  :hovered="hover"
                  hide-details
                  class="check mt-0 py-0 mr-4"
                  @change="item.status = $config.tasks.status.done; updateTaskStatus(item)"
                />
              </v-hover>
            </td>
            <td
              class="text-captions-1 pl-0"
              :style="{cursor: canEditOrComplete(item) ? 'pointer' : 'auto'}"
              @click="updateTaskFormField(item, 'name')"
            >
              <v-tooltip top color="rgba(47, 49, 53, 0.7)" max-width="350">
                <template #activator="{attrs, on}">
                  <div class="text-truncate task-name" v-bind="attrs" v-on="on">{{ item.name }}</div>
                </template>
                <div class="text-captions-1">
                  {{ item.name }}
                </div>
              </v-tooltip>
            </td>
            <td>
              <div class="d-flex">
                <UiPrioritySelect
                  class="mr-2"
                  v-model="item.priority"
                  :disabled="!editEnable(item)"
                  :items="priorities"
                  append-icon=""
                  dense
                  hide-details
                  @change="updateTask(item)"
                />
                <UiTaskStatusSelect
                  v-model="item.status"
                  :items="statuses"
                  append-icon=""
                  dense
                  hide-details
                  @change="updateTaskStatus(item)"
                />
              </div>
            </td>
            <td>
              <UIDatePicker
                class="date-picker"
                v-model="item.due_date"
                :append-to-body="false"
                :disabled="!editEnable(item)"
                :disabled-date="disabledBeforeToday"
                @save="(data) => {item.due_date = data.value; updateTask(item)}"
              >
                <template v-slot:input>
                  <div class="cursor-pointer">
                    <div class="text-captions-2" v-html="formatDueDate(item.due_date)"></div>
                  </div>
                </template>
              </UIDatePicker>
            </td>
            <td>
              <div class="d-flex">
                <div class="d-flex align-center">
                  <v-tooltip color="accent" top>
                    <template v-slot:activator="{ on, attrs }">
                      <UiAvatar
                        v-bind="attrs" v-on="on"
                        :src="item.assignee.avatar ? item.assignee.avatar.url : ''"
                        :text="item.assignee.first_name"
                        size="24"
                        class="avatar mr-7"
                      />
                    </template>
                    <span>{{ item.assignee.first_name }} {{ item.assignee.last_name }}</span>
                  </v-tooltip>
                  <TaskTabMenu
                    :task="item"
                    :openTaskModal="openTaskModal"
                    :updateTaskFormField="updateTaskFormField"
                    :openSubTaskModal="openSubTaskModal"
                    :editEnable="editEnable"
                    :deleteTask="deleteTask"
                    @onClick="setActiveTaskInTaskTab(item)"
                  />
                </div>
                <div v-if="item.loading" class="d-flex align-center justify-center absolute inset-0 gray-30">
                  <v-progress-circular :size="32" color="accent" indeterminate/>
                </div>
              </div>
            </td>
          </tr>

          <tr
            v-for="(subTask, idx) in item.children"
            :key="subTask.id"
            class="relative sub-task"
          >
            <td class="sub-task-icon overflow-hidden pr-0">
              <div :class="['sub-task-icon__item relative', {'last': idx === item.children.length - 1}]"/>
            </td>
            <td
              class="d-flex align-center pl-0 text-captions-1"
              :style="{cursor: canEditOrComplete(subTask) ? 'pointer' : 'auto'}"
            >
              <v-hover v-slot="{ hover }">
                <UiCheckbox
                  :readonly="subTask.status === $config.tasks.status.done"
                  :input-value="subTask.status === $config.tasks.status.done"
                  color="accent-100"
                  :hovered="hover"
                  hide-details
                  class="check mt-0 py-0 mr-4"
                  @change="subTask.status = $config.tasks.status.done; updateTaskStatus(subTask)"
                ></UiCheckbox>
              </v-hover>
              <span class="text-overflow-ellipsis overflow-hidden text-no-wrap"
                    @click="updateTaskFormField(subTask, 'name')">{{ subTask.name }}</span>
            </td>
            <td>
              <div class="d-flex">
                <UiPrioritySelect
                  class="mr-2"
                  v-model="subTask.priority"
                  :disabled="!editEnable(subTask)"
                  :items="priorities"
                  append-icon=""
                  dense
                  hide-details
                  @change="updateTask(subTask)"
                />
                <UiTaskStatusSelect
                  v-model="subTask.status"
                  :items="statuses"
                  append-icon=""
                  dense
                  hide-details
                  @change="updateTaskStatus(subTask)"
                />
              </div>
            </td>
            <td>
              <UIDatePicker
                class="date-picker"
                v-model="subTask.due_date"
                :append-to-body="false"
                :disabled="!editEnable(subTask)"
                :disabled-date="disabledBeforeToday"
                @save="(data) => {subTask.due_date = data.value; updateTask(subTask)}"
              >
                <template v-slot:input>
                  <div class="cursor-pointer">
                    <div class="text-captions-2" v-html="formatDueDate(subTask.due_date)"></div>
                  </div>
                </template>
              </UIDatePicker>
            </td>
            <td>
              <div class="d-flex">
                <div class="d-flex align-center">
                  <v-tooltip color="accent" top>
                    <template v-slot:activator="{ on, attrs }">
                      <UiAvatar
                        v-bind="attrs" v-on="on"
                        :src="subTask.assignee.avatar ? subTask.assignee.avatar.url : ''"
                        :text="subTask.assignee.first_name"
                        size="24"
                        class="avatar mr-7"
                      />
                    </template>
                    <span>{{ subTask.assignee.first_name }} {{ subTask.assignee.last_name }}</span>
                  </v-tooltip>
                  <TaskTabMenu
                    :task="subTask"
                    :openTaskModal="openTaskModal"
                    :updateTaskFormField="updateTaskFormField"
                    :openSubTaskModal="openSubTaskModal"
                    :editEnable="editEnable"
                    :deleteTask="deleteTask"
                    @onClick="setActiveTaskInTaskTab(subTask)"
                  />
                </div>
                <div v-if="subTask.loading" class="d-flex align-center justify-center absolute inset-0 gray-30">
                  <v-progress-circular :size="32" color="accent" indeterminate/>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div v-if="loading" class="d-flex justify-center align-center pa-6">
        <v-progress-circular :size="32" color="accent" indeterminate/>
      </div>
    </div>

    <v-footer height="70" color="gray-30" class="d-flex justify-end flex-shrink-0 px-6"
              v-if="!loading && getPermission($route.params.project_id)['project-s-drawer']['can-create-task-p-s-d']"
    >
      <UiBtn color="accent" width="132"
             @click="setActiveTaskInTaskTab(null); openTaskModal()"
      >
        <IconPlus width="18" class="mr-2"/>
        New Task
      </UiBtn>
    </v-footer>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {format, isSameDay, formatDistance} from 'date-fns';
import TaskTabMenu from '@/components/project/side-drawer/TaskTabMenu';
import UIDatePicker from "@/components/UI/UIDatePicker";
import UiPrioritySelect from '@/components/UI/UiPrioritySelect'
import UiTaskStatusSelect from "@/components/UI/UiTaskStatusSelect";
import UiAvatar from "@/components/UI/UiAvatar";
import UiBtn from "@/components/UI/UiBtn";

import UiCheckbox from "@/components/UI/UiCheckbox";

export default {
  name: 'TasksTab',
  components: {
    IconPlus: () => import('@/components/icons/IconPlus'),
    TaskTabMenu,
    UIDatePicker,
    UiCheckbox,
    UiPrioritySelect,
    UiTaskStatusSelect,
    UiAvatar,
    UiBtn,
  },
  data() {
    return {
      loading: true,
      headers: [
        {text: '', value: 'checkbox', sortable: false, width: '15px'},
        {text: 'Task name', value: 'name', sortable: false, width: '180px'},
        {text: 'Priority', value: 'priority', sortable: false, width: '70px'},
        {text: 'Due date', value: 'due_date', sortable: false, width: '80px'},
        {text: 'Assigned to', value: 'assignee', sortable: false, width: '64px'},
        // {text: '', value: '', sortable: false, width: '20px'},
      ],
      statuses: Object.values(this.$config.tasks.status),
      priorities: Object.values(this.$config.tasks.priority),
      formFocusElements: {
        assigneeFocus: false,
        nameFocus: false,
      },
      sortModel: {
        col: 'name',
        order: 'asc',
      }
    }
  },
  computed: {
    ...mapGetters({
      activeTask: 'getActiveTask',
      getProjectSideDrawer: 'getProjectSideDrawer',
      getAuthId: 'getAuthId',
      getProject: 'getProject',
      getPermission: 'getPermission',
      getProjectPermissionData: 'getProjectPermissionData',
      getProjectSideDrawerTasks: 'getProjectSideDrawerTasks',
      getProjectSideDrawerFilters: 'getProjectSideDrawerFilters',
      showProjectSideDrawer: 'showProjectSideDrawer'
    })
  },
  watch: {
    sortModel: {
      deep: true,
      async handler() {
        await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'tasks', data: []});
        await this.loadTasks(`projects/${this.$route.params.project_id}/tasks`);
      }
    },
    showProjectSideDrawer(val) {
      if (val) {
        if (this.activeTask) {
          this.openTaskModal()
        }
        this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'tasks', data: []});
        this.loadTasks(`projects/${this.$route.params.project_id}/tasks`).finally(() => {
          this.loading = false;
        })
      }
    }
  },
  async created() {
    await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'tasks', data: []});
    await this.loadTasks(`projects/${this.$route.params.project_id}/tasks`);
    this.loading = false;
  },
  mounted() {
    if (this.activeTask) {
      this.openTaskModal()
    }
    this.$eventBus.$on('updateSidDrawerFilters', async data => {
      const tabFilters = this.getProjectSideDrawer.filtersByTab.tasks;
      const updatedFilterItems = Object.keys(data);
      const updateNeeded = updatedFilterItems.some(filterItem => tabFilters.includes(filterItem) && JSON.stringify(data[filterItem]) !== JSON.stringify(this.getProjectSideDrawerFilters[filterItem]));
      if (updateNeeded) {
        await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'tasks', data: []});
        await this.loadTasks(`projects/${this.$route.params.project_id}/tasks`, {
          ...this.getProjectSideDrawerFilters,
          ...data,
        });
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('updateSidDrawerFilters');
  },
  methods: {
    ...mapActions([
      'setActiveTaskInTaskTab'
    ]),
    editEnable(task) {
      if (task === null) return false;

      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (task.created_by.id === this.getAuthId ||
        this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager)
      ) && this.getPermission(this.$route.params.project_id)['project-s-drawer']['can-edit-task-p-s-d'];
    },
    canEditOrComplete(task) {
      return this.editEnable(task) || task.assignee.id === this.getAuthId;
    },
    async openTaskModal() {
      let taskData = {
        id: this.activeTask?.id || null,
        project_id: this.getProject.id,
        project_tag_id: this.activeTask?.project_tag_id || this.getProjectSideDrawerFilters.project_tag_id,
        project_style_id: this.activeTask?.project_style_id || this.getProjectSideDrawerFilters.project_style_id,
        project_module_id: this.activeTask?.project_module_id || this.getProjectSideDrawerFilters.project_module,
        parent: {id: this.activeTask?.parent?.id || null},
      };

      if (this.activeTask) {
        taskData = {...this.activeTask, ...taskData}
      }

      this.getProjectSideDrawer.isSideDrawerPermanent = true;

      await this.$store.dispatch('openModal', {
        modalName: 'createNewTaskModal',
        data: {
          type: this.activeTask?.id ? 'edit' : 'create',
          taskData,
          formFocusElements: this.formFocusElements
        },
        handlers: {
          onCreate: task => {
            this.updateTaskList('create', task);
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          },
          onUpdate: task => {
            this.updateTaskList('update', task);
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          },
          onClose: () => {
            this.$store.dispatch('setActiveTaskInTaskTab', null);
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          }
        }
      });
    },
    async openSubTaskModal() {
      if (this.activeTask.parent !== null) return;

      this.getProjectSideDrawer.isSideDrawerPermanent = true;

      await this.$store.dispatch('openModal', {
        modalName: 'createNewTaskModal',
        data: {
          type: 'create',
          taskData: {
            id: null,
            project_id: this.getProject.id,
            project_tag_id: this.activeTask?.project_tag_id || this.getProjectSideDrawerFilters.project_tag_id,
            project_style_id: this.activeTask?.project_style_id || this.getProjectSideDrawerFilters.project_style_id,
            project_module_id: this.activeTask?.project_module_id || this.getProjectSideDrawerFilters.project_module,
            parent: {id: this.activeTask.id},
          },
        },
        handlers: {
          onCreate: task => {
            this.updateTaskList('create', task);
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          },
          onUpdate: task => {
            this.updateTaskList('update', task);
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          },
          onClose: () => {
            this.$store.dispatch('setActiveTaskInTaskTab', null);
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          }
        }
      });
    },
    async updateTaskFormField(task, field) {
      if (!this.canEditOrComplete(task)) return;

      this.setActiveTaskInTaskTab(task);
      this.formFocusElements[`${field}Focus`] = true;
      await this.openTaskModal();
      this.formFocusElements[`${field}Focus`] = false;
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // setHours need as date came with 00 time
      return date < today;
    },
    formatDueDate(date) {
      if (isSameDay(new Date(), new Date(date))) {
        return `Today <br> <strong class="text-lowercase">${format(date, 'h:mm aa')}</strong>`;
      } else {
        return formatDistance(new Date(date), new Date(), {addSuffix: true}).replace('about ', '');
      }
    },
    customSort(items, sortBy, sortDesc) {
      if (sortBy.length && sortDesc.length) {
        this.sortModel.col = sortBy[0];
        this.sortModel.order = sortDesc[0] ? 'desc' : 'asc';
      }
      return items;
    },
    async updateTaskStatus(task) {
      task.loading = true;

      try {
        const res = await this.$api.task.updateStatus(this.$route.params.project_id, task.id, {status: task.status});
        this.updateTaskList('update', this.formatTaskFromBackend(res.data));
        this.$eventBus.$emit('updateSideDrawerProjectTask', {actionType: 'update', task: res.data});
      } catch (error) {
        console.error(error);
      }
    },
    updateTask(task) {
      if (!this.canEditOrComplete(task)) return;
      task.loading = true;

      this.$api.task.update(this.$route.params.project_id, task.id, {
        ...task,
        assignee_id: task.assignee.id,
        due_date: format(task.due_date, 'yyyy-MM-dd'),
        reminder: new Date(task.reminder),
        attachments: task.attachments.map(file => file.id),
      })
        .then(res => {
          this.updateTaskList('update', this.formatTaskFromBackend(res.data));

          this.$toast.open({
            message: 'Task updated successfully',
            type: 'success',
            position: 'top-right'
          });
          this.$eventBus.$emit('updateSideDrawerProjectTask', {actionType: 'update', task: res.data});
        })
        .catch(err => {
          console.error(err)
        });
    },
    deleteTask() {
      this.getProjectSideDrawer.isSideDrawerPermanent = true;
      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want <br> to delete “${this.activeTask.name}“?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: () => {
            this.$store.dispatch('closeModal', 'confirmModal');

            let data = this.getProjectSideDrawerTasks.data.map(task => task.id === this.activeTask.id ? {
              ...task,
              loading: true
            } : task);

            if (this.activeTask.parent !== null) {
              data = this.getProjectSideDrawerTasks.data.map(task => {
                if (task.children.find(subTask => subTask.id === this.activeTask.id)) {
                  return {
                    ...task,
                    children: task.children.map(subTask => subTask.id === this.activeTask.id ? {
                      ...subTask,
                      loading: true
                    } : subTask),
                  }
                }
                return task;
              })
            }

            this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'tasks', data});

            this.$api.task.delete(this.$route.params.project_id, this.activeTask.id)
              .then(() => {
                this.updateTaskList('delete', this.activeTask);

                this.$toast.open({
                  message: 'Task deleted successfully',
                  type: 'success',
                  position: 'top-right'
                });

                this.$eventBus.$emit('updateSideDrawerProjectTask', {actionType: 'delete', task: this.activeTask});
                this.setActiveTaskInTaskTab(null);
                this.getProjectSideDrawer.isSideDrawerPermanent = false;
              })
              .catch(err => {
                console.error(err)
              });
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
            this.setActiveTaskInTaskTab(null);
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          },
        },
      });
    },
    async updateTaskList(type = '', targetTask) {
      let data;
      switch (type) {
        case 'create': {
          if (targetTask.parent === null) {
            data = [targetTask, ...this.getProjectSideDrawerTasks.data];
          } else {
            data = this.getProjectSideDrawerTasks.data.map(task => {
              if (task.id === targetTask.parent.id) {
                return {
                  ...task,
                  children: [...task.children, targetTask],
                }
              }
              return task;
            })
          }
          await this.$store.dispatch('closeModal', 'createNewTaskModal');
          break;
        }
        case 'update': {
          const count = this.getProjectSideDrawerTasks.data.length + 1
          await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'tasks', data: []});
          await this.loadTasks(`projects/${this.$route.params.project_id}/tasks`, {
            ...this.getProjectSideDrawerFilters,
            count
          });
          data = this.getProjectSideDrawerTasks.data
          break;
        }
        case 'delete': {
          data = this.getProjectSideDrawerTasks.data.filter(task => task.id !== targetTask.id);

          if (targetTask.parent !== null) {
            data = this.getProjectSideDrawerTasks.data.map(task => {
              if (task.id === targetTask.parent.id) {
                return {
                  ...task,
                  children: task.children.filter(subTask => subTask.id !== targetTask.id),
                }
              }
              return task;
            })
          }
          break;
        }
      }

      await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'tasks', data});
    },
    async loadTasks(url, data = null) {
      this.loading = true;
      const {
        project_tag_id,
        project_style_id,
        assignee,
        priority,
        status,
        search,
        project_module,
        count
      } = data || this.getProjectSideDrawerFilters;
      const {col, order} = this.sortModel;

      const tasks = await this.$api.task.list(url, {
        project_tag_id,
        project_style_id,
        assignee,
        priority,
        status,
        search,
        col,
        order,
        count: count || 20,
        project_module: project_module ? [project_module] : null,
      });

      await this.$store.dispatch('setProjectSideDrawerTabData', {
        tab: 'tasks',
        data: [
          ...this.getProjectSideDrawerTasks.data,
          ...tasks.data.map(task => this.formatTaskFromBackend(task)),
        ],
        nextLink: tasks.links.next,
      });

      this.loading = false;
    },
    async loadMoreTasks() {
      const tasksBody = this.$refs.tasks_body;
      if (!this.getProjectSideDrawerTasks.nextLink || this.loading) return;
      if (tasksBody.scrollHeight - Math.abs(tasksBody.scrollTop) === tasksBody.clientHeight) {
        await this.loadTasks(this.getProjectSideDrawerTasks.nextLink);
      }
    },
    formatTaskFromBackend(task) {
      return {
        ...task,
        due_date: new Date(task.due_date).getTime(),
        reminder: new Date(task.reminder).getTime(),
        loading: false,
        children: task.children.map(child => ({
          ...child,
          loading: false,
          parent: {id:  task.id || null},
          due_date: new Date(child.due_date).getTime(),
          reminder: new Date(child.reminder).getTime(),
        }))
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.task-table {
  border-radius: unset;
  background-color: transparent !important;

  &::v-deep {
    .v-data-table__wrapper {
      overflow: initial;

      & > table {
        border-spacing: 0 4px !important;
      }

      & > table > thead {
        & > tr {

          &:hover {
            background: var(--v-gray-30-base) !important;
          }

          th {
            position: relative;
            font-weight: 400;
            font-size: 10px !important;
            height: 44px !important;
            color: var(--v-gray-80-base) !important;
            border-bottom: none !important;

            &:nth-child(2) {
              padding-left: 0 !important;
            }

            &::before {
              content: '';
              position: absolute;
              bottom: 12px;
              left: 0;
              width: 100%;
              border-bottom: 1px solid var(--v-gray-30-base);
            }

            &:first-child::before {
              right: 0;
              left: 24px;
            }

            &:last-child::before {
              width: calc(100% - 24px);
            }
          }
        }
      }

      & > table > tbody > tr {
        background-color: var(--v-gray-0-base);

        & > td {
          border-bottom: none !important;
          border-radius: 0 !important;

          &:nth-child(1) {
            padding-left: 26px !important;
          }

          &:nth-child(2) {
            span {
              max-width: 180px;
            }
          }
        }

        &.sub-task td:nth-child(1) {
          padding-left: 10px !important;
        }
      }
    }
  }
}

.check {
  &::v-deep {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.sub-task-icon {
  padding-left: 0 !important;

  &__item {
    &::before {
      content: '';
      position: absolute;
      right: 0;
      width: 50%;
      height: 1px;
      background: var(--v-gray-30-base);
    }

    &::after {
      content: '';
      position: absolute;
      height: 50px;
      background: var(--v-gray-30-base);
      width: 1px;
      bottom: 0;
      left: 50%;
      transform: translateY(50%);
    }

    &.last {
      &::after {
        transform: translateY(0);
      }
    }
  }
}

.avatar {
  border-color: var(--v-accent-base) !important;
}

.date-picker {
  &::v-deep {
    .mx-datepicker-popup {
      top: 0 !important;
    }
  }
}

.task-name {
  max-width: 164px;
}
</style>
